import './App.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import React, { useState, useEffect } from 'react';
import axios from "axios";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { blueGrey, grey } from '@mui/material/colors';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: grey [100] },
        text: {
          primary: blueGrey [300],
        },
  },
  
});


function App() {
  const [table, setTable] = useState([]);
  const [xml, setXML] = useState("");
  const [prevxml, setprevXML] = useState("");
  const [sourceIp, setSourceIp] = useState("192.168.3.15");
  // const [SOAPUrl, setSOAPUrl] = useState("http://172.19.3.21:1236/webservice/alarmWebService");
  const [SOAPUrl, setSOAPUrl] = useState("https://soap.sannoi.com/wsdl?wsdl");
  const [Output, setOutput] = useState("");
  

  useEffect(() => {
    const allURL = "/app/list"
    const interval = setInterval(() => {
      axios.get(allURL).then((response) => {
        setTable(response.data.message)  
      })
    }, 500); //set your time here. repeat every 5 seconds  
    return () => clearInterval(interval);
  }, []);
  
  function update(e){

    var result = table.filter((row) => { return row.ccID == e.target.value; })[0]

    var System = "BMS"
    var Hostname = "BMSWS000001"
    var Location = "XXX"
    var Equipment = result.name.split('.')[5]
    var AlarmDate = result.time
    var Description = result.name.split('.')[7] + " Alarm"
    var Component = "Testing Alarm"
    var Severity = result.status == "ACTIVE" ? "Critical" : "Clear"

    var sourceMessage = System + '|' + Hostname + '|' + Location + '|' + Equipment + '|' + AlarmDate + '|' + Description + '|' + Component +'|' + Severity
    
    

    if (xml.includes('<!-- Siemens Testing -->')){
      var tempxml = '<!-- Siemens Testing -->\n' + prevxml.replace('<tns:sourceMessage>string</tns:sourceMessage>', '<tns:sourceMessage>'+ sourceMessage +'</tns:sourceMessage>').replace('<tns:sourceIp>string</tns:sourceIp>', '<tns:sourceIp>'+ sourceIp +'</tns:sourceIp>');
    }else{
      setprevXML(xml)
      var tempxml = '<!-- Siemens Testing -->\n' + xml.replace('<tns:sourceMessage>string</tns:sourceMessage>', '<tns:sourceMessage>'+ sourceMessage +'</tns:sourceMessage>').replace('<tns:sourceIp>string</tns:sourceIp>', '<tns:sourceIp>'+ sourceIp +'</tns:sourceIp>');
    }
    setXML(tempxml)
  }

  function soap() {

    axios.post(SOAPUrl,
           xml,
           {headers:
             {'Content-Type': 'text/xml',
             }
           }).then(res=>{
             setOutput(res.data)
           }).catch(err=>{console.log(err)});
  }

  var handleChange = (event) => {
    if (event.target.id == "XML"){setXML(event.target.value)}
    if (event.target.id == "URL"){setSOAPUrl(event.target.value)}
    if (event.target.id == "SourceIP"){setSourceIp(event.target.value)}
  };

  return (
    <ThemeProvider theme={darkTheme}>    
    <div className="App">
      <CssBaseline />
      <Container maxWidth="lg">
        <TableContainer >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ccID</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Status</TableCell>                
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row) => (
              <TableRow
                key={row.ccID}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.ccID}
                </TableCell>
                <TableCell >{row.time}</TableCell> 
                <TableCell >{row.name}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                
                <TableCell align="right">
                  <Button variant="contained" onClick={update} value={row.ccID}>Update</Button>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Stack pt={2} direction="row" spacing={2}>
        <TextField id = "URL" style = {{width: '50%'}} label= "SOAP URL" InputLabelProps={{ shrink: true }} value={SOAPUrl} onChange={handleChange}/>
        <TextField id = "SourceIP" style = {{width: '50%'}} label= "Source IP" InputLabelProps={{ shrink: true }} value={sourceIp} onChange={handleChange}/>
        </Stack>

        <Stack pt={2}>
        <TextField
          id="XML"
          label= "INPUT SOAP XML"
          multiline
          rows={5}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={xml}
          onChange={handleChange}
        />
        </Stack>
        <Stack pt={2}>
          <Button variant="contained" onClick={soap}>Submit</Button>
        </Stack>

        {Output!= "" &&
        <Stack pt={2}>
        <TextField
          id="Output"
          label= "Output"
          multiline
          rows={5}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={Output}
        />
        </Stack>
        }        
        
      </Container>      
    </div>
    </ThemeProvider>
  );
}

export default App;
